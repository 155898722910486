import React, { FC } from 'react';
import { Container, Row } from '..';
import { Col } from '../Col';

interface TertiaryBoxProps {
    category?: string;
    title?: string | React.ReactElement;
    className?: string;
}

export const TertiaryBox: FC<TertiaryBoxProps> = ({ category, title, className, children }) => {
    return (
        <div className={`bg-tertiary text-white px-5 lg:px-0 py-10 sm:py-16 ${className || ''}`}>
            <Container>
                <Row className="justify-between">
                    <Col className="w-full sm:w-5/12 mb-5 sm:mb-0">
                        {category && <h3 className="text-white mb-2">{category}</h3>}
                        {title && <h2 className="text-white"><strong>{title}</strong></h2>}
                    </Col>
                    <Col className="w-full sm:w-5/12">
                        {children}
                    </Col>
                </Row>
            </Container>
        </div>
    )
}
