const ROUTES: any = {};

ROUTES.HOME = () => "/";
ROUTES.SOBRE = () => "/sobre";
ROUTES.EIXOS = () => "/eixos";
ROUTES.EIXOS_SLUG = (slug?: string) => `${ROUTES.EIXOS()}/${slug ? slug : ':slug'}`;
ROUTES.OBSERVATORIO = () => "/observatorio";
ROUTES.NOTICIAS = () => "/noticias";
ROUTES.NOTICIA = (id?: string) => `${ROUTES.NOTICIAS()}/${id ? id : ':id'}`;
ROUTES.IMPRENSA = () => "/imprensa";
ROUTES.IMPRENSA_ID = (id?: string) => `${ROUTES.IMPRENSA()}/${id ? id : ':id'}`;
ROUTES.TRANSPARENCIA = () => "/transparencia";
ROUTES.TRANSPARENCIA_PAINEIS = () => `${ROUTES.TRANSPARENCIA()}/paineis`;
ROUTES.TRANSPARENCIA_PARCEIROS = () => `${ROUTES.TRANSPARENCIA()}/parceiros`;
// ROUTES.EDITAIS = () => "/editais";
ROUTES.EDITAISESELECOES = () => "/editais";
ROUTES.EDITALESELECAO = (id?: string) => `${ROUTES.EDITAISESELECOES()}/${id ? id : ':id'}`;
ROUTES.CONTATO = () => "/contato";
ROUTES.RELATORIOS = () => "/relatorios";

export { ROUTES };
