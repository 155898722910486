import { get } from "./api";

const Imprensa = {
    get: (cat = '', filtro = '', pageNumber = 1, pageSize = 50) => {
        return get(`/api/salaimprensas/listar?filtro=${filtro}&cat=${cat}&pageSize=${pageSize}&pageNumber=${pageNumber}`, true);
    },
    getId(id: string | number) {
        return get(`/api/salaimprensas/${id}`, true);
    }
}

export default Imprensa;