import { ROUTES } from "../../../constants";

export const posts  = [
    {
        image: '/img/_temp/home-post-1.png',
        category: 'Sala de Imprensa',
        title: 'Acompanhe a repercussão das nossas ações em outras mídias',
        description: 'Saiba todas as novidades do Instituto Fenae Transforma nas nossas redes sociais. Lá você encontra atualizações sobre processos internos e outro projetos sociais, como o Eu Faço Cultura e o Movimento Solidário.',
        buttonLabel: 'Ver mais',
        buttonLink: ROUTES.IMPRENSA
    },
    {
        image: '/img/_temp/home-post-2.png',
        category: 'Nossa história',
        title: 'Saiba um pouco mais sobre como foi o caminho do Instituto até hoje',
        description: 'Criado em 2007, o Instituto tinha incialmente o propósito de ser a estrutura responsável por abrigar o Programa Movimento Solidário.',
        buttonLabel: 'Ver mais',
        buttonLink: ROUTES.SOBRE
    }
];