import React from "react";
import { Container, Square } from "..";
import { SocialNetworks } from "..";

export function Footer() {
    return (
        <Container className="bg-primary text-white py-5 flex flex-col-reverse sm:flex-row justify-around items-center text-center" bgFloat="bg-primary" bgFloatPosition="right">
            <p><span className="block sm:inline-block">FENAE Transforma</span> © 2021 todos os direitos reservados</p>
            <div className="mb-5 sm:mb-0">
                <SocialNetworks />
            </div>
            <Square position="bl" className="bg-white hidden sm:block" />
        </Container>
    )
}