import React, { FC } from 'react';

interface ContainerProps {
    className?: string;
    bgFloat?: string;
    bgFloatPosition?: 'right' | 'left';
}

export const Container: FC<ContainerProps> = ({className, bgFloat, bgFloatPosition = 'left', children}) => {
    return (
        <div className={`container relative mx-auto ${className || ''}`}>
            {children}
            {bgFloat && <div className={`absolute top-0 h-full w-full ${bgFloatPosition === 'left' ? 'right-full': 'left-full'} ${bgFloat}`}></div>}
        </div>
    )
}
