import { get } from "./api";

const Editais = {
    get: (cat = '', filtro = '', slugType = '', pageNumber = 1, pageSize = 50) => {
        return get(`/api/editais/listar?filtro=${filtro}&tag=${slugType}&pageSize=${pageSize}&pageNumber=${pageNumber}`, true);
    },
    getId(id: string | number) {
        return get(`/api/editais/${id}`, true);
    }
}

export default Editais;