import { data } from './data';
import './style.scss';

export default function Timeline() {
    return (
        <div className="timeline">
            <header>
                <div className="timeline__title">
                    <strong>
                        Fenae<br />
                        Transforma
                    </strong>
                </div>
            </header>
            <div className="content">
                {
                    data.map((item, index) => {
                        const isRight = index % 2 === 0;
                        return (
                            <div key={index} className={`timeline__content ${isRight ? 'right' : 'left'}`}>
                                <div className={`timeline__content--row`}>
                                    <p>{item?.date}</p>
                                    <h3><strong>{item?.title}</strong></h3>
                                    <div className="pt-1" dangerouslySetInnerHTML={{ __html: item?.description }} />
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}