export function SocialNetworks() {
    return (
        <ul className="flex space-x-3 text-2xl">
            <li><a href="https://www.facebook.com/Instituto-Fenae-Transforma-353169362390451/?ref=page_internal" target="_blank"  className="inline-block" title="facebook"><i className="block icon-facebook" /></a></li>
            <li><a href="https://twitter.com/fenaetransforma" target="_blank"  className="inline-block" title="twitter"><i className="block icon-twitter" /></a></li>
            <li><a href="https://www.instagram.com/fenaetransforma/" target="_blank"  className="inline-block" title="instagram"><i className="block icon-instagram" /></a></li>
            {/* <li><a href="#this" target="_blank"  className="inline-block" title="youtube"><i className="block icon-youtube" /></a></li> */}
            {/* <li><a href="#this" target="_blank"  className="inline-block" title="linkedin"><i className="block icon-linkedin" /></a></li> */}
        </ul>
    )
}