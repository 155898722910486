import React from 'react';
import { BannerHeader, Col, Container, FooterNavigate, Link, Row } from '../../components';

import './style.scss';

export default function TransparenciaPaineisPage() {
    return (
        <>
            <BannerHeader
                title={
                    <span>
                        Painéis<br />
                    de resultado
                </span>
                }
                imgs={
                    {
                        desktop: '/img/_temp/bg-transparencia-paineis.png',
                        mobile: '/img/_temp/bg-transparencia-paineis-mobile.png'
                    }
                }
            />
            <div className="bg-tertiary">
                <Container className="text-center">
                    <Row className="justify-center">
                        <Col className="w-full sm:w-9/12 lg:w-1/2">
                            <h2 className="text-white py-16">
                                <strong>
                                    Veja os números já registrados por nossos projetos em andamento.
                            </strong>
                            </h2>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="paineis">
                <div style={{ backgroundColor: '#F4F4F4' }}>
                    <Container className="px-5 sm:px-0">
                        <Row className="items-center py-16">
                            <Col className="w-4/12 sm:w-2/12">
                                <img src={require('../../assets/imgs/logo-eu-faco-cultura.png').default} alt="" />
                            </Col>
                            <Col className="w-8/12 sm:w-10/12 space-y-2">
                                <h2><strong>Programa Eu Faço Cultura</strong></h2>
                                <p>Resultados relevantes em 4 anos:</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="box">
                                <img src={require('../../assets/imgs/transparencia/programas/eu-faco-cultura/1.png').default} alt="" />
                                <p>Distribuiu 358.720 cupons de livros, CDs, DVDs, ingressos de cinema, teatro e exposições.</p>
                            </Col>
                            <Col className="box">
                                <img src={require('../../assets/imgs/transparencia/programas/eu-faco-cultura/2.png').default} alt="" />
                                <p>Amparou 463 produtores culturais.</p>
                            </Col>
                            <Col className="box">
                                <img src={require('../../assets/imgs/transparencia/programas/eu-faco-cultura/3.png').default} alt="" />
                                <p>Mais de 1.442 produtos já passaram pela vitrine.</p>
                            </Col>
                            <Col className="box">
                                <img src={require('../../assets/imgs/transparencia/programas/eu-faco-cultura/4.png').default} alt="" />
                                <p>Chegou a 840 cidades com produtos</p>
                            </Col>
                            <Col className="box">
                                <img src={require('../../assets/imgs/transparencia/programas/eu-faco-cultura/5.png').default} alt="" />
                                <p>Amparou 4.703 pessoas com deficiência, atendidas por programas sociais do governo idosos e MEIs.</p>
                            </Col>
                            <Col className="box">
                                <img src={require('../../assets/imgs/transparencia/programas/eu-faco-cultura/6.png').default} alt="" />
                                <p>300 instituições apoiadas, sendo 134 escolas e 166 ONGs.</p>
                            </Col>
                        </Row>
                        <div className="text-center py-10">
                            <Link to="https://www.eufacocultura.com.br/" className="btn btn-primary">Saiba mais sobre o programa</Link>
                        </div>
                    </Container>
                </div>
                <Container className="px-5 sm:px-0">
                    <Row className="items-center py-16">
                        <Col className="w-4/12 sm:w-2/12">
                            <img src={require('../../assets/imgs/logo-movimento-solidario.png').default} alt="" />
                        </Col>
                        <Col className="w-8/12 sm:w-10/12 space-y-2">
                            <h2><strong>Programa Movimento Solidário</strong></h2>
                            <p>Em 5 anos de atuação em Belágua (MA), o Movimento Solidário:</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="box">
                            <img src={require('../../assets/imgs/transparencia/programas/movimento-solidario/1.png').default} alt="" />
                            <p>Implantou 42 projetos comunitários</p>
                        </Col>
                        <Col className="box">
                            <img src={require('../../assets/imgs/transparencia/programas/movimento-solidario/2.png').default} alt="" />
                            <p>Impactou positivamente 27 comunidades</p>
                        </Col>
                        <Col className="box">
                            <img src={require('../../assets/imgs/transparencia/programas/movimento-solidario/3.png').default} alt="" />
                            <p>Beneficiou um total de 1.928 pessoas entre eles</p>
                        </Col>
                        <Col className="box">
                            <img src={require('../../assets/imgs/transparencia/programas/movimento-solidario/4.png').default} alt="" />
                            <p>Implantou 15 tanques de peixes  projetos de piscicultura</p>
                        </Col>
                        <Col className="box">
                            <img src={require('../../assets/imgs/transparencia/programas/movimento-solidario/5.png').default} alt="" />
                            <p>Doou 484  óculos de grau </p>
                        </Col>
                        <Col className="box">
                            <img src={require('../../assets/imgs/transparencia/programas/movimento-solidario/6.png').default} alt="" />
                            <p>Criou a marca de mel Flor Mirim e vem preservando a abelha sem ferrão </p>
                        </Col>
                        <Col className="box">
                            <img src={require('../../assets/imgs/transparencia/programas/movimento-solidario/7.png').default} alt="" />
                            <p>Construiu 9 poços artesianos</p>
                        </Col>
                        <Col className="box">
                            <img src={require('../../assets/imgs/transparencia/programas/movimento-solidario/8.png').default} alt="" />
                            <p>Construiu uma casa de farinha </p>
                        </Col>
                        <Col className="box">
                            <img src={require('../../assets/imgs/transparencia/programas/movimento-solidario/9.png').default} alt="" />
                            <p>Criou e capacitou a comunidade para o manejo de 10 hortas comunitárias </p>
                        </Col>
                        <Col className="box">
                            <img src={require('../../assets/imgs/transparencia/programas/movimento-solidario/10.png').default} alt="" />
                            <p>Introduziu um projetos de avicultura</p>
                        </Col>
                        <Col className="box">
                            <img src={require('../../assets/imgs/transparencia/programas/movimento-solidario/11.png').default} alt="" />
                            <p>Criou um projeto de codorna </p>
                        </Col>
                        <Col className="box">
                            <img src={require('../../assets/imgs/transparencia/programas/movimento-solidario/12.png').default} alt="" />
                            <p>criou 2 projetos de suinocultuta </p>
                        </Col>
                        <Col className="box">
                            <img src={require('../../assets/imgs/transparencia/programas/movimento-solidario/13.png').default} alt="" />
                            <p>Entregou 9 banheiros, melhorando o saneamento básico em comunidades que sofriam - - com doenças relacionadas a falta de sanemaneto </p>
                        </Col>
                        <Col className="box">
                            <img src={require('../../assets/imgs/transparencia/programas/movimento-solidario/14.png').default} alt="" />
                            <p>Fez a doação de computadores para instalar um telecentro na escola municipal do município </p>
                        </Col>
                    </Row>
                    <div className="text-center py-10">
                        <Link to="https://movimentosolidario.fenae.org.br/" className="btn btn-primary">Saiba mais sobre o programa</Link>
                    </div>
                </Container>
            </div>
            <FooterNavigate />
        </>
    )
}