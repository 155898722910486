import React, { FC } from 'react';
import { Link as LinkRouter } from 'react-router-dom';

interface LinkProps {
    className?: string;
    to: string;
}

export const Link: FC<LinkProps> = (props) => {
    const newProps: any = {...props};
    const isExternalLink = typeof newProps.to === 'string' && /^https?:\/\//.test(newProps.to);
    if(isExternalLink) {
        newProps.href = newProps.to;
        delete newProps.to;
    }
    return isExternalLink
        ? <a {...newProps} target="_blank">{newProps.children}</a>
        : <LinkRouter {...newProps}>{newProps.children}</LinkRouter>
}
