import React, { FC } from 'react';
import { Link } from '..';
import './style.scss';

interface CardGrayProps {
    className?: string;
    icon?: string;
    title: string;
    description: string;
    description2: any;
    link?: string;
}

export const CardGray: FC<CardGrayProps> = ({ icon, title, description, description2, link, className }) => {
    return (
        <div className={`card-gray-component ${className || ''}`}>
            {icon && <i className={`text-4xl text-primary ${icon}`} />}
            {title && <h4 className="text-2xl"><strong>{title}</strong></h4>}
            {description && <p>{description}</p>}
            {description2 && <p>{description2}</p>}
            {link && (
                <div className="text-right">
                    <Link
                        className="btn btn-primary outline only-icon"
                        to={link}
                    >
                        
                        <img src={require('../../assets/imgs/arrow-right-primary.svg').default} alt="" />
                    </Link>
                </div>
            )}
        </div>
    )
}
