import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { BannerHeader, Card, CategoriesAndSearch, CardList, Col, Container, FooterNavigate, Loading, Row } from '../../components';
import { ROUTES } from '../../constants';
import { Services } from '../../services';
import { useLocation } from 'react-router-dom';


interface EditaisPageProps {
  slug?: string;
  limit?: number;
}

export default function EditaisESelecoesPage(props: EditaisPageProps) {

  const [loading, setLoading] = useState(true);
  const [catSelected, setCatSelected] = useState<any>();
  const [search, setSearch] = useState('');
  const [dataCat, setDataCat] = useState<Array<any>>([]);
  const [data, setData] = useState<any>();

  const location = useLocation()

  useEffect(() => {
    loadToken();
  }, [catSelected]);

  async function loadToken() {
    setLoading(true);
    try {

      const respNot = await Services.Editais.get(catSelected, search, props?.slug, 1, props?.limit);
      setData(respNot?.data);
    } catch (error) {
      alert('Algo deu errado. Atualize a página e tente novamente.');
    } finally {
      setLoading(false);
    }
  }

  function handleCategory(value: string) {
    setCatSelected(value);
  }

  return (
    <>
      {
        location.pathname == ROUTES.EDITAISESELECOES() && (
          <BannerHeader
            title={<span>Editais e seleções</span>}
            imgs={
              {
                desktop: '/img/_temp/bg-editais.png',
                mobile: '/img/_temp/bg-noticias-mobile.png'
              }
            }
            subTitle={
              {
                text: 'Programa Eu Faço Cultura',
                paragraph: 'Além de analisar os contextos sociais com todos os elementos que os compõem, é necessário propor intervenções em diferentes áreas para gerar transformações significativas, capazes de dar origem a soluções para os problemas sociais contemporâneos.'
              }
            }

          />
        )
      }
      <Container>
        <div className="text-center py-16">
          <h2><strong>Lorem ipsulum dolor vestibulum mollis</strong></h2>
          {
            location.pathname == ROUTES.EDITAISESELECOES() && (
              <div>
                {
                  !!dataCat?.length && (

                    <CategoriesAndSearch
                      items={dataCat}
                      onChangeCategory={handleCategory}
                      hideSearch={true}
                    />
                  )
                }
              </div>
            )
          }

        </div>
      </Container>
      <div className="sm:px-60 py-10 bg-cinza">
        {
          loading
            ? (
              <Loading />
            )
            : (
              <>
                <div className="container px-6 sm:px-0 mx-auto">
                  <Row className="">
                    {
                      data?.edital?.map((item: any, index: number) => (
                        <Col key={index} className="w-full sm:w-1/1 mb-12">
                          <CardList
                            title={item.titulo}
                            descricao={item.descricao}
                            dataInicioInscricao={item.dataInicioInscricao}
                            dataFimInscricao={item.dataFimInscricao}
                            link={
                              {
                                pathname: ROUTES.EDITALESELECAO(item.id),
                                state: {
                                  data: item
                                }
                              }
                            }
                            image={item.urlThumb}
                            urlEdital={item.urlEdital}
                            altImagem={item.altImagem}
                            date={moment(item.data).format('DD [de] MMMM [de] YYYY')}
                            category={item.categoria}
                          />
                        </Col>
                      ))
                    }
                  </Row>
                </div>
              </>
            )
        }
      </div>
      {
        location.pathname == ROUTES.EDITAISESELECOES() && (
          <FooterNavigate />
        )
      }

    </>
  )
}