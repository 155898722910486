import React, { FC } from 'react';
import { Link } from '..';
import './style.scss';

import Moment from 'moment';

interface CardProps {
  descricao?: boolean;
  title?: string;
  dataInicioInscricao?: string;
  dataFimInscricao?: string;
  date?: string;
  category?: string;
  link: any;
  image?: string;
  altImagem?: string;
  urlEdital?: string;
}

export const CardList: FC<CardProps> = ({ descricao, title, urlEdital,  link, image, altImagem, dataInicioInscricao, dataFimInscricao }) => {
  let active = 
      (Moment(dataInicioInscricao).format('YYYYMMDD') >= Moment(new Date()).format('YYYYMMDD'))
      && (Moment(dataFimInscricao).format('YYYYMMDD') <= Moment(new Date()).format('YYYYMMDD'));
  return (
    <div className="listCard">
      <Link to={link} className="listCard-image" >
        {image && <img src={image} alt={altImagem} />}
      </Link>
      <Link to={link}>
        <div className="card-component__content card-component-list bg-white">
          <div className="context">
            <h3>{title}</h3>
            <p>{descricao}</p>
            <span>Para mais informações:</span>
            <a href={urlEdital} target="_blank" >Clique aqui</a>
          </div>
          <div className="actions">
            <div className="context-actions">
              <span className={`${active ? "active" : ""}`}>Inscrições {active ? "abertas" : "encerradas"}</span>
              <button>Veja o edital</button>
            </div>
          </div>
          {/* 
          {title && <h4 className="mt-2 text-lg text-justify"><strong>{title}</strong></h4>}
          {subTitle && <p className="mt-2 text-justify">{subTitle}</p>} */}
        </div>
      </Link>
    </div>
  )
}
