import React from 'react';
import { Header, Footer, Nav } from '../../components';
import { useLayout } from '../../contexts';
import RoutesLayout from '../../routes/layout';
import SplashPage from "../Splash";

export default function Layout() {
    const layoutContext = useLayout();
    const { fullLoading } = layoutContext;

    if (fullLoading) {
        return <SplashPage />;
    }
    return (
        <div className="flex-1 flex flex-col overflow-x-hidden">
            <Header />
            <Nav />
            <main className="flex-1 relative">
                <RoutesLayout />
            </main>
            <Footer />
        </div>
    )
}