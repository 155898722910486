import React, { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import { useParams, useLocation } from 'react-router-dom';
import { BannerHeader, Container, FooterNavigate, Loading } from '../../components';
import { ROUTES } from '../../constants';
import { Services } from '../../services';
import share from '../../assets/imgs/share-button.svg';
import facebook from '../../assets/imgs/facebook.png';
import twitter from '../../assets/imgs/twitter.png';
import linkedin from '../../assets/imgs/linkedin.png';
import './style.scss';
import Helmet from 'react-helmet';

import {
  FacebookShareButton, LinkedinShareButton, TwitterShareButton
} from 'react-share';

export default function PostPage(props: any) {
  const params: any = useParams();
  const [data, setData] = useState<any>();
  const [type, setType] = useState<any>(0);
  const [loading, setLoading] = useState(true);
  const [invalidId, setInvalidId] = useState(false);
  const [openTooltip, setOpenTooltip] = useState(false);
  const [openTooltip2, setOpenTooltip2] = useState(false);
  const toggleChecked = () => setOpenTooltip(value => !value);
  const toggleChecked2 = () => setOpenTooltip2(value => !value);
  const location = useLocation()
  useEffect(() => {
    if (props?.location?.state) {
      setData(props?.location?.state?.data);
      setLoading(false);
    } else {
      loadData(params?.id);
    }
    console.log(type)
  }, []);

  async function loadData(id: any) {
    setLoading(true);
    let service: any;
    switch (props?.match?.path) {
      case ROUTES.NOTICIA():
        service = Services.Noticias.getId;
        break;
      case ROUTES.IMPRENSA_ID():
        service = Services.Imprensa.getId;
        break;
      case ROUTES.EDITALESELECAO():
        service = Services.Editais.getId;
        break;
    }

    try {
      const resp = await service(id);
      if (resp?.data) {
        setData({ ...resp?.data })
        return;

      }

      setInvalidId(true);
    } catch (error) {
      alert('Algo deu errado. Tente novamente.')
    } finally {
      setLoading(false);
    }
  }

  function getType() {
    let strType = '';

    switch (props?.match?.path) {
      case ROUTES.EDITALESELECAO():
        strType = 'Editais e Seleções'
        break;
      case ROUTES.NOTICIA():
        strType = 'Notícias'
        break;
      case ROUTES.IMPRENSA_ID():
        strType = 'Sala de imprensa'
        break;
    }
    return strType;

  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{data?.titulo}</title>
        <meta name="description" content={data?.titulo} />
        <meta property="og:title" content={data?.subTitulo} />
        <meta property="og:image" content={data?.urlDestaque} />
      </Helmet>
      {
        data?.dataFormatada && (
          <BannerHeader
            category={getType()}
            imgs={
              {
                desktop: props?.match?.path === ROUTES.EDITALESELECAO() ? '/img/_temp/bg-editais.png' :'/img/_temp/bg-noticias.png',
                // desktop: data?.urlDestaque,
                mobile: props?.match?.path === ROUTES.EDITALESELECAO() ? '/img/_temp/bg-editais-mobile.png' : '/img/_temp/bg-noticias-mobile.png',
              }
            }
          />
        )
      }
      {
        !data?.dataFormatada && (
          <BannerHeader
            category={getType()}
            imgs={
              {
                desktop: props?.match?.path === ROUTES.EDITALESELECAO() ? '/img/_temp/bg-editais.png' :'/img/_temp/bg-noticias.png',
                // desktop: data?.urlDestaque,
                mobile: props?.match?.path === ROUTES.EDITALESELECAO() ? '/img/_temp/bg-editais-mobile.png' : '/img/_temp/bg-noticias-mobile.png',
              }
            }
          />
        )
      }
      {
        loading
          ? <Loading />
          : invalidId
            ? (
              // quando não existir o id na requisição
              <h2 className="text-center py-10">
                Página inválida
              </h2>
            )
            : (
              <div className="px-5 lg:px-0">
                <Container className="py-16 notice">
                  <h2>{data?.titulo}</h2>
                  <h3 className="text-gray-2 mt-3">{data?.subTitulo}</h3>
                  <div className="flex w-full justify-center items-center">
                    <img className="w-full my-5" src={data?.urlDestaque} alt={data?.alt} />
                  </div>
                  <div className="flex justify-between items-end">
                    {
                      data?.dataFormatada && (
                        <div className="text-sm w-1/2">
                          <span>Publicado em {data?.dataFormatada} por {data?.autor}</span>
                        </div>
                      )
                    }
                    {
                      !data?.dataFormatada && (
                        <div className="text-sm w-1/2">
                          <span>Publicado por {data?.autor}</span>
                        </div>
                      )
                    }
                    <div className="flex relative">
                      <div><p className="text-right mr-4">Compartilhe em <br />suas redes sociais:</p></div>
                      <div>
                        <button className="focus:outline-none" onClick={toggleChecked}><img src={share} alt="" /></button>
                      </div>
                      {
                        openTooltip && (
                          <div className=" arrow_box">
                            <ul className="flex">
                              <li><FacebookShareButton url={window.location.href}><img className="pt-5 pb-4 px-5" src={facebook} alt="" /> </FacebookShareButton></li>
                              <li><LinkedinShareButton url={window.location.href}><img className="pt-5 pb-4 px-5" src={linkedin} alt="" /></LinkedinShareButton></li>
                              <li><TwitterShareButton url={window.location.href}><img className="pt-5 pb-4 px-5" src={twitter} alt="" /></TwitterShareButton></li>
                            </ul>
                          </div>
                        )
                      }
                    </div>
                  </div>
                  <hr className="border-gray-1 mb-16 mt-3" />
                  {
                    data?.codeYoutube && (
                      <div className="w-full relative mb-10">
                        <ReactPlayer
                          style={{ maxWidth: 560, marginLeft: 'auto', marginRight: 'auto' }}
                          controls={true}
                          width="100%"
                          url={`https://www.youtube.com/embed/${data?.codeYoutube}`}
                        />
                      </div>
                    )
                  }
                  <div className="relative">
                    <div className="space-y-5" dangerouslySetInnerHTML={{ __html: data?.texto || '' }} />
                    <div className="flex justify-end">
                      <div className="flex relative mt-5">
                        <div><p className="text-right mr-4">Compartilhe em <br />suas redes sociais:</p></div>
                        <div>
                          <button className="focus:outline-none" onClick={toggleChecked2}><img src={share} alt="" /></button>
                        </div>
                        {
                          openTooltip2 && (
                            <div className=" arrow_box">
                              <ul className="flex">
                                <li><FacebookShareButton url={window.location.href}><img className="pt-5 pb-4 px-5" src={facebook} alt="" /> </FacebookShareButton></li>
                                <li><LinkedinShareButton url={window.location.href}><img className="pt-5 pb-4 px-5" src={linkedin} alt="" /></LinkedinShareButton></li>
                                <li><TwitterShareButton url={window.location.href}><img className="pt-5 pb-4 px-5" src={twitter} alt="" /></TwitterShareButton></li>
                              </ul>
                            </div>
                          )
                        }
                      </div>
                    </div>
                  </div>
                  <hr className="border-gray-1 mb-16 mt-3" />
                </Container>
              </div>
            )

      }
      <FooterNavigate />
    </>
  )
}