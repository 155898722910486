import { get } from "./api";

const Noticias = {
    get: (cat = '', filtro = '', slugType = '', pageNumber = 1, pageSize = 50) => {
        return get(`/api/noticias/listar?filtro=${filtro}&cat=${cat}&tag=${slugType}&pageSize=${pageSize}&pageNumber=${pageNumber}`, true);
    },
    getId(id: string | number) {
        return get(`/api/noticias/${id}`, true);
    }
}

export default Noticias;