import React from 'react';
import { Col, Container, Link, Row } from '../../../components';
import { ROUTES } from '../../../constants';
import './style.scss';

export default function Nav() {
    return (
        <div className="relative z-10">
            <div className="nav-home">
                <Container bgFloat="sm:bg-primary" bgFloatPosition="right">
                    <Row>
                        <Col className="hidden lg:block lg:w-1/12"></Col>
                        <Col className="w-full relative lg:w-11/12">
                            {/* barra azul mobile */}
                            <div className="absolute w-full h-5/6 bottom-0 -right-10 bg-tertiary sm:hidden" />
                            {/* end barra azul mobile */}
                            <div className="bg-primary pb-8 ml-0 sm:ml-5 mr-5 sm:mr-0" />
                            <Row className="relative flex-col sm:flex-row justify-between text-white bg-primary pl-10 pb-8">
                                <Col className="self-center">
                                    <p className="text-xl sm:text-2xl"><strong><span className="sm:block">Eixos</span> de atuação</strong></p>
                                </Col>
                                <Col className="nav-item">
                                    <Link to={ROUTES.EIXOS_SLUG('educacao')}>
                                        <i className="icon-educacao" />
                                        <span>Educação</span>
                                    </Link>
                                </Col>
                                <Col className="nav-item">
                                    <Link to={ROUTES.EIXOS_SLUG('desenvolvimento-sustentavel')}>
                                        <i className="icon-desenvolvimento" />
                                        <span>Desenvolvimento<br />sustentável</span>
                                    </Link>
                                </Col>
                                <Col className="nav-item">
                                    <Link to={ROUTES.EIXOS_SLUG('projetos-incentivados')}>
                                        <i className="icon-projetos" />
                                        <span>Projetos<br />incentivados</span>
                                    </Link>
                                </Col>
                                <Col className="nav-item">
                                    <Link to={ROUTES.EIXOS_SLUG('impacto-social')}>
                                        <i className="icon-impacto" />
                                        <span>Impacto<br />social</span>
                                    </Link>
                                </Col>
                                {/* <Col className="nav-item">
                                    <Link to="/">
                                        <i className="icon-observatorio" />
                                        <span>Observatório<br />social</span>
                                    </Link>
                                </Col> */}
                                {/* <Col className="self-center">
                                    <Link to="/" className="btn btn-white outline text-center">
                                        Ver mais
                                    </Link>
                                </Col> */}
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}