import React from "react";
import { BannerHeader, FooterNavigate, TertiaryBox } from "../../components/";
import "./style.scss";


export default function ContatoPage() {
    
    return (
        <>
            <BannerHeader
                title="Contato"
                imgs={
                    {
                        desktop: '/img/_temp/bg-contato.png',
                        mobile: '/img/_temp/bg-contato-mobile.png'
                    }
                }
            />
            <TertiaryBox 
                title={<span>Fale com a gente</span>}
                
            >   
                <iframe width="400" height="200" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3839.2912834174826!2d-47.87441588572221!3d-15.788587727017015!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x935a3b06665c6949%3A0x52762d8d17023dd2!2sEdificio%20Ion!5e0!3m2!1spt-BR!2sbr!4v1579611608124!5m2!1spt-BR!2sbr" ></iframe>
                <div className="space-y-5 mb-5">
                    <p>
                        <strong>Endereço:</strong><br />
                        SGAN 601, Lote H, Asa Norte, Brasília/DF, 70830-019
                        (Ala Amarela da Impact Hub Brasília)
                    </p>
                    {/*<p>
                        <strong>Telefones:</strong><br />
                        (61) 1242-2144<br />
                        (61) 1242-2144 <br />
                    </p>*/}
                    <p>
                        <strong>E-mail:</strong> <br />
                        relacionamento@fenae.org.br
                    </p>
                </div>
            </TertiaryBox>
            <FooterNavigate />
        </>
    )
}