import React, { FC } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Container } from '..';
import ArrowLeft from '../../assets/imgs/arrow-left.svg';
import './style.scss';

interface BannerHeaderProps {
  title?: string | React.ReactElement;
  category?: string;
  mini?: boolean;
  imgs?: {
    desktop: string;
    mobile?: string;
  }
  subTitle?: {
    text: string;
    paragraph?: string;
  }
}

export const BannerHeader: FC<BannerHeaderProps> = ({ title, category, mini, imgs, subTitle, children }) => {
  const history = useHistory();
  const location = useLocation();

  const bannerClass = "sm:absolute top-0 left-0 h-full w-full object-cover";
  return (
    <div className={`banner-header-component ${mini === true ? 'mini' : ''}`}>
      {children && (
        <div className="absolute z-10 flex items-center inset-0">
          <div className="absolute w-full sm:w-1/2 h-full bg-white bg-opacity-75"></div>
          <Container>
            <div className="w-full sm:w-1/2">
              {children}
            </div>
          </Container>
        </div>
      )}
      {imgs && (
        <picture>
          {imgs.mobile && <source media="(max-width: 639px)" srcSet={imgs.mobile} className={bannerClass} />}
          <img className={bannerClass} src={imgs.desktop} alt="" />
        </picture>
      )}
      <div className="absolute over botton-0 left-0 w-full z-10">
        <Container className="py-11 sm:py-24 px-5 lg:px-0">
          {location.pathname !== '/' && (
            <button onClick={() => history.goBack()} className="btn btn-white outline only-icon mb-2 sm:mb-4">
              <img src={ArrowLeft} alt="Voltar" />
            </button>
          )}
          {
            category && (
              <h3 className="text-white"><strong>{category}</strong></h3>
            )
          }
          {
            title && (
              <h2 className="text-white"><strong>{title}</strong></h2>
            )
          }
        </Container>
        {subTitle &&
          <section className="section-sub">
            <Container>
              <h3>{subTitle.text}</h3>
              <p>{subTitle.paragraph}</p>
            </Container>
          </section>
        }
      </div>
    </div>
  )
}
