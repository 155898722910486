export const posts = [
  {
    image: "/img/_temp/sobre/post-1.png",
    title: "Integração é a chave para o futuro",
    description:
      "E do que as pessoas precisam quando se reúnem com o propósito de gerar impacto social? Precisam alinhar aspectos metodológicos, nivelar informações, absorver objetos culturais para aumentar o campo das partilhas e conhecer mais sobre o universo dos negócios sociais! É por essa razão que apostamos na integração de coletivos inteligentes orientados pelo valor da solidariedade.",
  },
  {
    image: "/img/_temp/sobre/post-2.png",
    title: "Sustentabilidade para segurança ambiental, econômica e cultural",
    description:
      "Tecer vínculos sociais fortes passa pela contínua propagação da sustentabilidade como sendo o resultado de um exercício da soma dos esforços de toda uma cadeia de parceiros. Estes participam de atividades que geram impacto ambiental, econômico e cultural, a fim de que a diversidade de experiências de vida concorram para o surgimento de novas tecnologias sociais.",
  },
];
