import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Container, Row, Square } from '../../../components';
import { ROUTES } from '../../../constants';

export default function Transparencia() {
    return (
        <div className="relative z-2">
            <div className="absolute bottom-0 w-full h-full pt-10">
                <div className="h-full w-full bg-primary"></div>
            </div>
            <Container className="relative" bgFloat="bg-secondary" bgFloatPosition="left">
                <div className="relative bg-secondary flex flex-col sm:flex-row justify-center items-center px-5 lg:px-0 py-10 space-y-5 sm:space-y-0 lg:w-11/12">
                    <div className="sm:w-3/12">
                        <h3><strong>Transparência</strong></h3>
                    </div>
                    <Row className="flex flex-col sm:flex-row flex-1 items-center text-center sm:text-left text-white space-y-5 sm:space-y-0">
                        <Col className="w-6/12 sm:flex-1">
                            <i className="icon-pessoas text-primary text-4xl" />
                            <h3><strong>358.720</strong></h3>
                            <p>cupons de produtos culturais foram distribuídos.</p>
                        </Col>
                        <Col className="w-6/12 sm:flex-1">
                            <i className="icon-livro text-primary text-4xl" />
                            <h3><strong>840</strong></h3>
                            <p>cidades contempladas.</p>
                        </Col>
                        <Col className="w-6/12 sm:flex-1">
                            <i className="icon-computador text-primary text-4xl" />
                            <h3><strong>300</strong></h3>
                            <p>ONGs e escolas apoiadas.</p>
                        </Col>
                    </Row>
                    <div className="sm:w-2/12 flex justify-center">
                        <Link to={ROUTES.TRANSPARENCIA} className="btn btn-primary outline"><span className="text-white">Ver mais</span></Link>
                    </div>
                    <div className="lg:w-1/12"></div>
                    <Square className="bg-primary" />
                </div>
            </Container>
        </div>
    )
}