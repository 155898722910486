import axios from "axios";
import { TOKEN } from "../constants";
import { getQuery } from "../utils";

interface TokenProps {
  access_token: string;
  expires_in: number;
  refresh_token: string;
  token_type: string;
}

async function serverToken(refresh_token?: string) {
  const query: any = {
    grant_type: "password",
    client_id: "fenaeInstitucional",
    client_secret: "be11c2f1d5894b6ba72084718c40503a",
  };

  if (refresh_token) {
    query.grant_type = "refresh_token";
    query.refresh_token = refresh_token;
  }

  const https = await https$(false);
  const resp = await https.post("/token", getQuery(query, ""));
  const data = { ...resp?.data };
  data.expires_in = Date.now() + data.expires_in * 1000;
  localStorage.setItem(TOKEN, JSON.stringify(data));
  return data;
}

async function getToken() {
  try {
    const storageToken: any = (await localStorage.getItem(TOKEN)) || null;
    let token: TokenProps = JSON.parse(storageToken);

    if (token && Date.now() >= token?.expires_in) {
      token = await serverToken(token?.refresh_token);
    } else if (!token) {
      token = await serverToken();
    }

    return token;
  } catch (error) {
    throw "";
  }
}

async function https$(isAuth = true) {
  const instance = axios.create({
    baseURL: process.env.REACT_APP_API,
    timeout: 35000
  });

  if (isAuth) {
    let token: TokenProps = await getToken();
    if (!token) {
      throw "";
    }
    instance.defaults.headers.common.Authorization = `${token?.token_type} ${token?.access_token}`;
  }
  return instance;
}

export async function get(url: string, isAuth = true) {
  try {
    const http = await https$(isAuth);
    return http.get(url);
  } catch (error) {
    if (isAuth) {
      localStorage.removeItem(TOKEN);
    }
  }
}

export async function post(url: string, body = {}, isAuth = true) {
  try {
    const https = await https$(isAuth);
    return https.post(url, body);
  } catch (error) {
    if (isAuth) {
      localStorage.removeItem(TOKEN);
    }
  }
}
