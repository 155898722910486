import React, { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { Container, SocialNetworks } from '..';
import { ROUTES } from '../../constants';
import { useLayout } from '../../contexts';
import './style.scss';

interface NavProps {
}

export const Nav: FC<NavProps> = () => {
    const { nav, setNav } = useLayout();
    function handleNav() {
        setNav(false);
    }
    if(!nav) {
        return null;
    }
    return (
        <div className="nav-component">
            <Container className="flex bg-opacity-100" bgFloat="bg-secondary">
                <div className="nav-component--content">
                    <div className="nav-component--content__items">
                        <h3 className="text-tertiary">FENAE Transforma</h3>
                        <div className="nav-links text-2xl text-white">
                            <NavLink onClick={handleNav} to={ROUTES.HOME} activeClassName="text-primary" exact>Início</NavLink>
                            <span className="nav-links--dot">•</span>
                            <NavLink onClick={handleNav} to={ROUTES.SOBRE} activeClassName="text-primary">Sobre</NavLink><br />
                            {/* <span className="nav-links--dot">•</span> */}
                            <NavLink onClick={handleNav} to={ROUTES.EIXOS} activeClassName="text-primary">Eixos de atuação</NavLink>
                            {/* <NavLink onClick={handleNav} to={ROUTES.OBSERVATORIO} activeClassName="text-primary">Observatório social</NavLink> */}
                             <span className="nav-links--dot">•</span>
                            <NavLink onClick={handleNav} to={ROUTES.NOTICIAS} activeClassName="text-primary">Notícias</NavLink><br />
                            {/* <NavLink onClick={handleNav} to={ROUTES.EDITAISESELECOES} activeClassName="text-primary">Editais e Seleções</NavLink><br /> */}
                            
                            {/* <a onClick={handleNav} href="https://google.com.br" target="_blank">Acesse os cursos</a> */}
                            
                            <NavLink onClick={handleNav} to={ROUTES.IMPRENSA} activeClassName="text-primary">Sala de Imprensa</NavLink><span className="nav-links--dot">•</span>
                            <NavLink onClick={handleNav} to={ROUTES.CONTATO} activeClassName="text-primary">Entre em contato</NavLink><br/>
                            <a href="https://edu.fenaetransforma.org.br/" target="_blank" >Acesse os cursos</a><span className="nav-links--dot">•</span><NavLink onClick={handleNav} to={ROUTES.TRANSPARENCIA} activeClassName="text-primary">Transparência</NavLink><br />
                        </div>
                        <div className="text-white">
                            <SocialNetworks />
                        </div>
                    </div>
                </div>
                <button
                    className="btn-close"
                    onClick={handleNav}
                >
                    <img src={require('../../assets/imgs/close.svg').default} alt="" />
                </button>
            </Container>
        </div>
    )
}
