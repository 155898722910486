import React from 'react';
import { BannerHeader, Col, Container, FooterNavigate, Row } from '../../components';
import './style.scss';

export default function TransparenciaParceirosPage() {
    return (
        <>
            <BannerHeader
                title={
                    <span>
                        Parceiros
                    </span>
                }
                imgs={
                    {
                        desktop: '/img/_temp/bg-transparencia-parcerias.png',
                        mobile: '/img/_temp/bg-transparencia-parcerias-mobile.png'
                    }
                }
            />
            <Container className="transparencia-parcerias">
                <Row className="transparencia-parcerias__row">
                    <Col className="hidden sm:block w-2/12"></Col>
                    <Col className="w-full sm:w-3/12"><img src={require('../../assets/imgs/transparencia/parcerias/apcef.png').default} alt=""/></Col>
                    <Col className="hidden sm:block w-1/12"></Col>
                    <Col className="w-full sm:w-5/12 transparencia-parcerias__row--content">
                        <h2><strong>Apcefs</strong></h2>
                        <p>As Associações do Pessoal da Caixa Econômica Federal são entidades que congregam os empregados Caixa em torno de iniciativas que beneficiam não só a categoria, mas toda a sociedade através da criação e sustento financeiro de projetos de responsabilidade social. </p>
                        <a target="_blank" href="https://www.fenae.org.br/portal/fenae-portal/apcefs/" className="btn btn-primary">Acesse o site</a>
                    </Col>
                    <Col className="hidden sm:block w-1/12"></Col>
                </Row>
                <Row className="transparencia-parcerias__row">
                    <Col className="hidden sm:block w-2/12"></Col>
                    <Col className="w-full sm:w-3/12"><img src={require('../../assets/imgs/transparencia/parcerias/wiz.png').default} alt=""/></Col>
                    <Col className="hidden sm:block w-1/12"></Col>
                    <Col className="w-full sm:w-5/12 transparencia-parcerias__row--content">
                        <h2><strong>Wiz Soluções</strong></h2>
                        <p>A Wiz realiza distribuição de serviços financeiros e de seguros através de sua plataforma multicanal. Transforma dados em conhecimento e conecta pessoas a empresas, oportunidades à necessidades, para potencializar negócios. Ao longo dos mais de 40 anos de atuação, desenvolve e implementa plataformas integradas de relacionamento e venda, garantindo agilidade e presença em todo território nacional.</p>
                        <a target="_blank" href="https://wizsolucoes.com.br/" className="btn btn-primary">Acesse o site</a>
                    </Col>
                    <Col className="hidden sm:block w-1/12"></Col>
                </Row>
                <Row className="transparencia-parcerias__row">
                    <Col className="hidden sm:block w-2/12"></Col>
                    <Col className="w-full sm:w-3/12"><img src={require('../../assets/imgs/transparencia/parcerias/caixa-seguradora.png').default} alt=""/></Col>
                    <Col className="hidden sm:block w-1/12"></Col>
                    <Col className="w-full sm:w-5/12 transparencia-parcerias__row--content">
                        <h2><strong>Caixa Seguradora</strong></h2>
                        <p>É a instituição responsável pelo Fundo de Garantia por Tempo de Serviço (FGTS), pelo Programa de Integração Social (PIS) e pelo Seguro-Desemprego, além de marcar presença em programas sociais do governo e em todas as Casas Lotéricas do país.</p>
                        <a target="_blank" href="https://www.caixaseguradora.com.br/Paginas/home-geral.aspx" className="btn btn-primary">Acesse o site</a>
                    </Col>
                    <Col className="hidden sm:block w-1/12"></Col>
                </Row>
                <Row className="transparencia-parcerias__row">
                    <Col className="hidden sm:block w-2/12"></Col>
                    <Col className="w-full sm:w-3/12"><img src={require('../../assets/imgs/transparencia/parcerias/governo-brasil.png').default} alt=""/></Col>
                    <Col className="hidden sm:block w-1/12"></Col>
                    <Col className="w-full sm:w-5/12 transparencia-parcerias__row--content">
                        <h2><strong>Governo Federal</strong></h2>
                        <p>Através da Lei de Incentivo à Cultura da Secretaria de Cultura do Ministério do Turismo, as ações do Programa Eu Faço Cultura são viabilizadas anualmente.</p>
                        <a target="_blank" href="https://www.gov.br/pt-br" className="btn btn-primary">Acesse o site</a>
                    </Col>
                    <Col className="hidden sm:block w-1/12"></Col>
                </Row>
                <Row className="transparencia-parcerias__row">
                    <Col className="hidden sm:block w-2/12"></Col>
                    <Col className="w-full sm:w-3/12"><img src={require('../../assets/imgs/transparencia/parcerias/governo-maranhao.png').default} alt=""/></Col>
                    <Col className="hidden sm:block w-1/12"></Col>
                    <Col className="w-full sm:w-5/12 transparencia-parcerias__row--content">
                        <h2><strong>Governo do Maranhão</strong></h2>
                        <p>Desde 2015, através do Programa Mais IDH da Secretaria de Estado dos Direitos Humanos e Participação Popular (SEDIHPOP), o Governo do Maranhão é parceiro do Instituto ao garantir apoio para execução dos projetos do Programa Movimento Solidário em Belágua (MA).</p>
                        <a target="_blank" href="https://www3.ma.gov.br/" className="btn btn-primary">Acesse o site</a>
                    </Col>
                    <Col className="hidden sm:block w-1/12"></Col>
                </Row>
                <Row className="transparencia-parcerias__row">
                    <Col className="hidden sm:block w-2/12"></Col>
                    <Col className="w-full sm:w-3/12"><img src={require('../../assets/imgs/transparencia/parcerias/hub.png').default} alt=""/></Col>
                    <Col className="hidden sm:block w-1/12"></Col>
                    <Col className="w-full sm:w-5/12 transparencia-parcerias__row--content">
                        <h2><strong>Impact Hub</strong></h2>
                        <p>Presente em mais de 55 países, o Impact Hub é uma iniciativa voltada para o estímulo à formação de comunidades de empreendedores, criação e propagação de programas de capacitação e uma série de outras ações voltadas para aceleração de impactos. </p>
                        <a target="_blank" href="https://brasilia.impacthub.net/" className="btn btn-primary">Acesse o site</a>
                    </Col>
                    <Col className="hidden sm:block w-1/12"></Col>
                </Row>
            </Container>
            <FooterNavigate />
        </>
    )
}