import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { BannerHeader, Card, CategoriesAndSearch, Col, Container, FooterNavigate, Loading, Row } from '../../components';
import { ROUTES } from '../../constants';
import { Services } from '../../services';
import { useLocation } from 'react-router-dom';


interface NoticiasPageProps {
  slug?: string;
  limit?: number;
}

export default function NoticiasPage(props: NoticiasPageProps) {



  const [loading, setLoading] = useState(true);
  const [catSelected, setCatSelected] = useState<any>();
  const [search, setSearch] = useState('');
  const [dataCat, setDataCat] = useState<Array<any>>([]);
  const [data, setData] = useState<any>();

  const location = useLocation()

  useEffect(() => {
    loadToken();
  }, [catSelected]);

  async function loadToken() {
    setLoading(true);
    try {
      const respCat = await Services.Categorias.get('noticias');
      const itemsCat = [...respCat?.data].map((item: any) => {
        return {
          value: item.id,
          label: item.nome,
        }
      });
      setDataCat([...itemsCat]);

      const respNot = await Services.Noticias.get(catSelected, search, props?.slug, 1, props?.limit);
      setData({ ...respNot?.data });
    } catch (error) {
      alert('Algo deu errado. Atualize a página e tente novamente.');
    } finally {
      setLoading(false);
    }
  }

  function handleCategory(value: string) {
    setCatSelected(value);
  }

  return (
    <>
      {
        location.pathname == ROUTES.NOTICIAS() && (
          <BannerHeader
            title={
              <span>
                Notícias
                    </span>
            }
            imgs={
              {
                desktop: '/img/_temp/bg-noticias.png',
                mobile: '/img/_temp/bg-noticias-mobile.png'
              }
            }
          />
        )
      }
      <Container>
        <div className="text-center py-16">
          <h2><strong>Acompanhe aqui nossas últimas novidades</strong></h2>
          {
            location.pathname == ROUTES.NOTICIAS() && (
              <div>
                {
                  !!dataCat?.length && (

                    <CategoriesAndSearch
                      items={dataCat}
                      onChangeCategory={handleCategory}
                    />
                  )
                }
              </div>
            )
          }

        </div>
      </Container>
      <div className="sm:px-60 py-10 bg-cinza">
        {
          loading
            ? (
              <Loading />
            )
            : (
              <>
                {
                  !!data?.noticiasDestaque?.length && (
                    <Row className="mb-5 sm:mb-16">
                      {
                        data?.noticiasDestaque?.map((item: any, index: number) => (
                          <Col key={index} className="w-full sm:w-1/2 mb-5">
                            <Card
                              title={item.titulo}
                              subTitle={item.subTitulo}
                              link={
                                {
                                  pathname: ROUTES.NOTICIA(item.id),
                                  state: {
                                    data: item
                                  }
                                }
                              }
                              isFeatured
                              image={item.urlThumb}
                              date={moment(item.data).format('DD [de] MMMM [de] YYYY')}
                              category={item.categoria}
                            />
                          </Col>
                        ))
                      }
                    </Row>
                  )
                }
                <div className="container px-6 sm:px-0 mx-auto">
                  <Row className="">
                    {
                      data?.noticias?.map((item: any, index: number) => (
                        <Col key={index} className="w-full sm:w-1/3 mb-5">
                          <Card
                            title={item.titulo}
                            subTitle={item.subTitulo}
                            link={
                              {
                                pathname: ROUTES.NOTICIA(item.id),
                                state: {
                                  data: item
                                }
                              }
                            }
                            image={item.urlThumb}
                            date={moment(item.data).format('DD [de] MMMM [de] YYYY')}
                            category={item.categoria}
                          />
                        </Col>
                      ))
                    }
                  </Row>
                </div>
              </>
            )
        }
      </div>
      {
        location.pathname == ROUTES.NOTICIAS() && (
          <FooterNavigate />
        )
      }

    </>
  )
}