import React, { FC, ReactElement } from 'react';
import { Container, Link, Square } from '..';
import './style.scss';

export interface PostFullProps {
    image?: string;
    category?: string;
    title?: string;
    description?: string;
    buttonLabel?: ReactElement | string;
    buttonLink?: any;
    isRight?: boolean;
    buttonDescription?: string;
}

export const PostFull: FC<PostFullProps> = (props) => {
    return (
        <div className={`post-full-component relative sm:flex items-center`}>
            {props?.image && (
                <div className={`img w-full sm:w-1/2 relative sm:absolute ${props?.isRight ? 'right-0' : 'left-0'}`}>
                    <Square position={props?.isRight ? 'tl' : 'br'} className="bg-white" />
                    <img className="h-full w-full object-cover" src={props.image} alt="" />
                </div>
            )}
            <Container className={`flex ${props?.isRight ? '' : 'justify-end'}`}>
                <div className="sm:w-1/2 px-5 lg:px-0 lg:w-5/12 space-y-5 py-10">
                    {props?.category && <h3 className="text-dark">{props.category}</h3>}
                    {props?.title && <h2><strong>{props.title}</strong></h2>}
                    {props?.description && <p>{props.description}</p>}
                    {props?.buttonDescription && <p className="text-sm -mb-4">{props.buttonDescription}</p>}
                    {props?.buttonLink && <Link to={props.buttonLink} className="btn btn-primary">{props?.buttonLabel || 'Ver mais'}</Link>}
                </div>
            </Container>
        </div>
    )
}

