import React from "react";
import {
  Switch,
  Route
} from "react-router-dom";
import { ROUTES } from "../constants";
import ContatoPage from "../pages/Contato";
import RelatoriosPage from "../pages/Relatorios";
import EditaisPage from "../pages/Editais";
import EixosPage from "../pages/Eixos";
import EixosSlugPage from "../pages/EixosSlug";
import HomePage from '../pages/Home';
import ImprensaPage from "../pages/Imprensa";
import NoticiasPage from "../pages/Noticias";
import ObservatorioPage from "../pages/Observatorio";
import PostPage from "../pages/Post";
import SobrePage from "../pages/Sobre";
import TransparenciaPage from "../pages/Transparencia";
import TransparenciaPaineisPage from "../pages/TransparenciaPaineis";
import TransparenciaParceirosPage from "../pages/TransparenciaParceiros";
import EditaisESelecoesPage from "../pages/EditaisESelecoes";
import EditaisPostPage from "../pages/Post/Editais";

export default function RoutesLayout() {
  return (
    <Switch>
      <Route path={ROUTES.EDITALESELECAO()} component={EditaisPostPage} />
      <Route path={ROUTES.EDITAISESELECOES()} component={EditaisESelecoesPage} />
      <Route path={ROUTES.CONTATO()} component={ContatoPage} />
      <Route path={ROUTES.RELATORIOS()} component={RelatoriosPage} />
      {/* <Route path={ROUTES.EDITAIS()} component={EditaisPage} /> */}
      <Route path={ROUTES.TRANSPARENCIA_PARCEIROS()} component={TransparenciaParceirosPage} />
      <Route path={ROUTES.TRANSPARENCIA_PAINEIS()} component={TransparenciaPaineisPage} />
      <Route path={ROUTES.TRANSPARENCIA()} component={TransparenciaPage} />
      <Route path={ROUTES.IMPRENSA_ID()} component={PostPage} />
      <Route path={ROUTES.IMPRENSA()} component={ImprensaPage} />
      <Route path={ROUTES.NOTICIA()} component={PostPage} />
      <Route path={ROUTES.NOTICIAS()} component={NoticiasPage} />
      <Route path={ROUTES.OBSERVATORIO()} component={ObservatorioPage} />
      <Route path={ROUTES.EIXOS_SLUG()} component={EixosSlugPage} />
      <Route path={ROUTES.EIXOS()} component={EixosPage} />
      <Route path={ROUTES.SOBRE()} component={SobrePage} />
      <Route path={ROUTES.HOME()} component={HomePage} />
    </Switch>
  );
}