import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { BannerHeader, Container, FooterNavigate } from '../../components';
import { posts } from '../Eixos/data';
import NoticiasPage from '../Noticias';
import './style.scss';

export default function EixosSlugPage() {
    const params: any = useParams();
    const [post, setPost] = useState<any>();

    useEffect(() => {
        const p = posts.find((item) => item.slug === params?.slug);
        setPost(p);
    }, []);
    console.log('pinto',params)
    if (!post) return null;

    return (
        <>
            <BannerHeader
                title={post?.title}
                imgs={
                    {
                        desktop: post?.banner,
                        mobile: post?.bannerMobile
                    }
                }
            />
            <div className="bg-tertiary text-white px-5 lg:px-0">
                <Container className="py-16">
                    <div className="space-y-5" dangerouslySetInnerHTML={{ __html: post?.content }} />
                </Container>
            </div>
            <NoticiasPage limit={3} slug={params?.slug}/>
            {/* TODO: cards */}
            <FooterNavigate />
        </>
    )
}