import React from "react";
import { Link } from "react-router-dom";
import { Container } from "..";
import { useLayout } from "../../contexts";
import './style.scss';
import './styles.scss';

export function Header() {
    const { setNav } = useLayout();

    function handleNav() {
        setNav(true);
    }
    return (
        <div className="header-component">
            <div className="header-component">
                <Container className="flex items-start" bgFloat="bg-white sm:bg-opacity-75" bgFloatPosition="left">
                    {/* <div className="bg-white sm:bg-opacity-75 flex-1 flex flex-col-reverse sm:flex-row justify-end">
                    <div className="flex-1 py-5 pl-5 lg:pl-0">
                        <Link to="/"><img src={require('../../assets/imgs/logo.png').default} alt=""/></Link>
                    </div>
                </div> */}
                    <div className="bg-white sm:bg-opacity-75 flex-1 flex flex-col-reverse sm:flex-row justify-end">
                        <div className="flex-1 py-5 pl-5 lg:pl-0">
                            <Link to="/"><img className="logo" src={require('../../assets/imgs/logo.png').default} alt="" /></Link>
                        </div>
                    </div>
                    <div className="fixed-menu  relative z-50">
                        <a href="https://edu.fenaetransforma.org.br/" target="_blank" className="btn-cursos">Conheça os cursos</a>
                        <button onClick={handleNav} className="btn-menu">
                            <img src={require('../../assets/imgs/menu.svg').default} alt="" />
                        </button>
                    </div>
                </Container>
            </div>
        </div>
    )
}