import React from 'react';
import { PostFull } from '../../../components';
import { posts } from './data';

export default function Posts() {
    return (
        <>
            {
                posts?.map((item, index) => (
                    <PostFull {...item} isRight={index % 2 !== 0} key={index} />
                ))
            }
        </>
    )
}