import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { BannerHeader, Card, CategoriesAndSearch, Col, Container, FooterNavigate, Loading, Row, TertiaryBox } from '../../components';
import { ROUTES } from '../../constants';
import { Services } from '../../services';

export default function ImprensaPage() {

    const [loading, setLoading] = useState(true);
    const [catSelected, setCatSelected] = useState<any>();
    const [search, setSearch] = useState('');
    const [dataCat, setDataCat] = useState<Array<any>>([]);
    const [data, setData] = useState<any>();

    useEffect(() => {
        loadToken();
    }, [catSelected]);

    async function loadToken() {
        setLoading(true);
        try {
            const respCat = await Services.Categorias.get('imprensa');
            const itemsCat = [...respCat?.data].map((item: any) => {
                return {
                    value: item.id,
                    label: item.nome,
                }
            });
            setDataCat([...itemsCat]);

            const respNot = await Services.Imprensa.get(catSelected, search);
            setData({ ...respNot?.data });
        } catch (error) {
            alert('Algo deu errado. Atualize a página e tente novamente.');
        } finally {
            setLoading(false);
        }
    }

    function handleCategory(value: string) {
        setCatSelected(value);
    }

    return (
        <>
            <BannerHeader
                title={
                    <span>
                        Sala<br />
                        de imprensa
                    </span>
                }
                imgs={
                    {
                        desktop: '/img/_temp/bg-imprensa.png',
                        mobile: '/img/_temp/bg-imprensa-mobile.png'
                    }
                }
            />
            <Container>
                <div className="text-center py-16">
                    <h2><strong>Acompanhe aqui nossas últimas novidades</strong></h2>
                    {
                        !!dataCat?.length && (
                            <CategoriesAndSearch
                                items={dataCat}
                                onChangeCategory={handleCategory}
                            />
                        )
                    }
                </div>
                {
                    loading
                        ? (
                            <Loading />
                        )
                        : (
                            <>
                                {
                                    !!data?.salaImprensaDestaque?.length && (
                                        <Row className="mb-5 sm:mb-16">
                                            {
                                                data?.salaImprensaDestaque?.map((item: any, index: number) => (
                                                    <Col key={index} className="w-full sm:w-1/2 mb-5">
                                                        <Card
                                                            title={item.titulo}
                                                            link={
                                                                {
                                                                    pathname: ROUTES.IMPRENSA_ID(item.id),
                                                                    state: {
                                                                        data: item
                                                                    }
                                                                }
                                                            }
                                                            isFeatured
                                                            image={item.urlThumb}
                                                            date={moment(item.data).format('DD [de] MMMM [de] YYYY')}
                                                            category={item.categoria}
                                                        />
                                                    </Col>
                                                ))
                                            }
                                        </Row>
                                    )
                                }
                                <div className="px-6 sm:px-0">
                                    <Row className="mb-16">
                                        {
                                            data?.salaImprensas?.map((item: any, index: number) => (
                                                <Col key={index} className="w-full sm:w-1/3 mb-5">
                                                    <Card
                                                        title={item.titulo}
                                                        link={
                                                            {
                                                                pathname: ROUTES.IMPRENSA_ID(item.id),
                                                                state: {
                                                                    data: item
                                                                }
                                                            }
                                                        }
                                                        image={item.urlThumb}
                                                        date={moment(item.data).format('DD [de] MMMM [de] YYYY')}
                                                        category={item.categoria}
                                                    />
                                                </Col>
                                            ))
                                        }
                                    </Row>
                                </div>
                            </>
                        )
                }
            </Container>
            <TertiaryBox
                title={<span>Fale com a gente</span>}
            >
                <div className="space-y-5">
                    <p>
                        <strong>Endereço:</strong><br />
                        SGAN 601, Lote H, Asa Norte, Brasília/DF, 70830-019
                        (Ala Amarela da Impact Hub Brasília)
                    </p>
                    <p>
                        <strong>Telefones:</strong><br />
                        (61) 1242-2144<br />
                        (61) 1242-2144 <br />
                    </p>
                    <p>
                        <strong>E-mail:</strong> <br />
                        instituto@fenaetransforma.org.br
                    </p>
                </div>
            </TertiaryBox>
            <FooterNavigate />
        </>
    )
}