import React from 'react';
import { BannerHeader, FooterNavigate, PostFull, TertiaryBox } from '../../components';
import { posts } from './data';

export default function EixosPage() {
    return (
        <>
            <BannerHeader
                title={
                    <span>
                        Eixos<br />
                        de atuação
                </span>
                }
                imgs={
                    {
                        desktop: '/img/_temp/bg-eixos.png',
                        mobile: '/img/_temp/bg-eixos-mobile.png'
                    }
                }
            />
            <TertiaryBox
                title="Visão sistêmica e foco em impactos positivos"
            >
                <p>Além de analisar os contextos sociais com todos os elementos que os compõem, é necessário propor intervenções em diferentes áreas para gerar transformações significativas, capazes de dar origem a soluções para os problemas sociais contemporâneos.</p>
            </TertiaryBox>
            {
                posts?.map((item, index) => (
                    <PostFull {...item} isRight={index % 2 === 0} key={index} />
                ))
            }
            <FooterNavigate />
        </>
    )
}