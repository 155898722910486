import React, { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row } from '..';
import { Col } from '../Col';
import './style.scss';
import ArrowLeft from '../../assets/imgs/arrow-left.svg';
import ArrowRight from '../../assets/imgs/arrow-right.svg';

interface BoxContent {
    title?: string;
    description?: string;
}

interface FooterNavigateProps {
    left?: BoxContent;
    right?: BoxContent;
    className?: string;
}

export const FooterNavigate: FC<FooterNavigateProps> = ({ left, right, className }) => {
    const [left$, setLeft$] = useState<BoxContent>({
        title: 'Sobre o FENAE Transforma',
        description: 'Uma iniciativa pautada na confiança de que grandes transformações são construções coletivas.  '
    })
    // const [right$, setRight$] = useState<BoxContent>({
    //     title: 'Observatório social',
    //     description: 'Boas decisões são guiadas por dados confiáveis e pesquisas consistentes. '
    // })
    const [right$, setRight$] = useState<BoxContent>({
        title: 'Eixos de atuação',
        description: 'Visão sistêmica e foco em impactos positivos.'
    })
    useEffect(() => {
        if (left) {
            setLeft$(left);
        }
        if (right) {
            setRight$(right);
        }
    });

    return (
        <div className={`footer-navigate-component bg-secondary text-white px-5 lg:px-0 py-16 sm:py-24 ${className || ''}`}>
            <Container>
                <Row className="justify-between">
                    <Col className="flex flex-col w-full sm:w-5/12">
                        <h3 className="sm:absolute sm:bottom-full pb-10 sm:pb-3 text-white">Conheça também</h3>
                        {left$?.title && <h2 className="text-white mb-3"><strong>{left$.title}</strong></h2>}
                        <div className="flex-1">
                            {left$?.description && <p>{left$.description}</p>}
                        </div>
                        <div className="text-right sm:text-left">
                            <Link to="/sobre" className="inline-flex sm:flex-row-reverse items-center mt-8">
                                <span className="mx-4 text-sm">Ver mais</span>
                                <span className="btn btn-white outline only-icon">
                                    <img className="hidden sm:inline-block" src={ArrowLeft} alt="" />
                                    <img className="sm:hidden" src={ArrowRight} alt="" />
                                </span>
                            </Link>
                        </div>
                    </Col>
                    <Col className="sm:hidden w-full border-b border-white my-10 mx-3" />
                    <Col className="flex flex-col w-full sm:w-5/12">
                        {right$?.title && <h2 className="text-white mb-3"><strong>{right$.title}</strong></h2>}
                        <div className="flex-1">
                            {right$?.description && <p>{right$.description}</p>}
                        </div>
                        <div className="text-right">
                            <Link to="/eixos" className="inline-flex items-center mt-8">
                                <span className="mx-4 text-sm">Ver mais</span>
                                <span className="btn btn-white outline only-icon">
                                    <img src={ArrowRight} alt="" />
                                </span>
                            </Link>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}
