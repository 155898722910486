import { ROUTES } from "../../constants";

export const posts = [
  {
    slug: "educacao",
    banner: "/img/_temp/eixos/banner-post-1.png",
    bannerMobile: "/img/_temp/eixos/banner-post-1-mobile.png",
    image: "/img/_temp/eixos/post-1.png",
    title: "Educação",
    description:
      "Conheça nossos projetos educativos que visam transformar os processos formativos em formas de engajamento. Nossa plataforma de ensino à distância conta com uma série de cursos para os parceiros estratégicos e seus associados.",
    content: `
      <p>Ampliar o acesso à educação passa pela criação de ferramentas e de mobilização de esforços para torná-las acessíveis. Desejamos transformar os processos formativos em formas de engajamento. Nosso intuito é que as redes de conhecimento construídas a partir de nossos processos de mentoria sirvam ao desenvolvimento pessoal de cada beneficiário e estimulem a criação de soluções personalizadas para os desafios enfrentados em diferentes localidades do Brasil.</p>
      <p><a class='intern-text' href='' target='_blank'>Nossa plataforma de ensino à distância</a> conta com uma série de cursos para os parceiros estratégicos e seus associados. Aulas sobre instrumentos musicais, gastronomia, empreendedorismo, capacitação, aperfeiçoamento e muito mais esperam por você!</p>
    `,
    buttonLabel: "Ver mais",
    buttonLink: ROUTES.EIXOS_SLUG("educacao"),
  },
  {
    slug: "projetos-incentivados",
    banner: "/img/_temp/eixos/banner-post-2.png",
    bannerMobile: "/img/_temp/eixos/banner-post-2-mobile.png",
    image: "/img/_temp/eixos/post-2.png",
    title: "Projetos Incentivados",
    description:
      "Nossos projetos buscam ampliar o acesso à arte no Brasil e ajudar aqueles que mais precisam dela. Saiba como fazer parte desta iniciativa incrível!",
    content: `
      <p>A Fenae também busca entregar desenvolvimento, cidadania e inclusão social através de projetos paralelos, utilizando Leis de Incentivo Fiscal nacionais ou regionais (esporte/cultura/projetos ou programas). O objetivo é garantir que parte dos impostos pagos por pessoas físicas e jurídicas sejam destinados a ações para fomento da cultura, do lazer e do esporte, como o exemplo do <a class='intern-text' href='https://www.eufacocultura.com.br/' target='_blank'>Eu Faço Cultura</a>.</p>
      <p>O Programa Eu Faço Cultura é o maior projeto apoiado pela Lei de Incentivo à Cultura do Governo Federal. Ele liga produtores culturais ao público: com o dinheiro captado através da destinação de parte do Imposto de Renda de empregados Caixa, o Programa distribui ingressos gratuitos para quem mais precisa, como PCDs, idosos e beneficiários de programas do governo, fomentando a circulação de bens culturais em todo o Brasil.</p>
    `,
    buttonLabel: "Ver mais",
    buttonLink: ROUTES.EIXOS_SLUG("projetos-incentivados"),
  },
  {
    slug: "desenvolvimento-sustentavel",
    banner: "/img/_temp/eixos/banner-post-3.png",
    bannerMobile: "/img/_temp/eixos/banner-post-3-mobile.png",
    image: "/img/_temp/eixos/post-3.png",
    title: "Desenvolvimento sustentável",
    description:
      "O Movimento Solidário aposta na execução de projetos estruturantes em áreas com baixos Índices de Desenvolvimento Humano (IDH). Saiba como participar e ajude a deixar o país mais sustentável!",
    content: `
      <p>Para a redução da desigualdade social, é necessário intervir em comunidades de baixa renda com projetos estruturantes. Alavancar o crescimento requer garantir segurança alimentar e inclusão produtiva para comunidades potencialmente proponentes de um novo modelo de sociedade, regida pelos valores do trabalho coletivo e de uma gestão dos bens comuns naturais, comprometida com a preservação de diferentes ecossistemas.</p>
      <p>O <a href='https://movimentosolidario.fenae.org.br/' target='_blank' class='intern-text'>Movimento Solidário</a> é o programa de desenvolvimento sustentável da Fenae que é visto como modelo consolidado há 15 anos para erradicação da extrema pobreza. Ele aposta na execução de projetos estruturantes em áreas com baixos Índices de Desenvolvimento Humano (IDH), a fim de garantir segurança alimentar e inclusão produtiva, em observância aos Objetivos de Desenvolvimento Sustentável (ODS) da ONU.</p>
    `,
    buttonLabel: "Saiba mais",
    buttonLink: ROUTES.EIXOS_SLUG("desenvolvimento-sustentavel"),
  },
  {
    slug: "impacto-social",
    banner: "/img/_temp/eixos/banner-post-4.png",
    bannerMobile: "/img/_temp/eixos/banner-post-4-mobile.png",
    image: "/img/_temp/eixos/post-4.png",
    title: "Impacto social",
    description:
      "Negócios também podem impactar positivamente as camadas mais baixas da sociedade. Conheça a plataforma da Yunus e saiba como mudar o mundo!",
    content: `
      <p>Negócios também podem impactar positivamente as camadas mais baixas da sociedade. Iniciativas financeiramente sustentáveis e com caráter socioambiental podem, além de transformar a vida de populações menos favorecidas, preservar o meio ambiente e fomentar o mercado destas comunidades.</p>
      <p>Possibilitar a replicação de experiências exitosas para a transformação da realidade é uma forma eficaz de diminuir as desigualdades sociais. Inúmeras tecnologias sociais estão à espera de condições básicas para serem implementadas e impactar positivamente a vida de milhares de pessoas. Pensando nisso, através da <a href='https://www.yunusnegociossociais.com/' target='_blank' class='intern-text'>plataforma de investimentos da Yunus Negócios Sociais</a>, a Fenae apoia  outros empreendimentos sociais.</p>
    `,
    buttonLabel: "Ver mais",
    buttonLink: ROUTES.EIXOS_SLUG("impacto-social"),
  },
];
