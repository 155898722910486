import { FC } from 'react';

interface LoadingProps {
    className?: string;
}

export const Loading: FC<LoadingProps> = ({ className }) => {
    return (
        <div className={`flex justify-center my-10  ${className || ''}`}>
            <img src={require('../../assets/imgs/loading.svg').default} width="100" alt="" />
        </div>
    )
}
