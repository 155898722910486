import React from 'react';
import { BannerHeader, CardGray, Col, Container, FooterNavigate, Row, TertiaryBox } from '../../components';
import { ROUTES } from '../../constants';

export default function TransparenciaPage() {
    return (
        <>
            <BannerHeader
                title="Transparência"
                imgs={
                    {
                        desktop: '/img/_temp/bg-transparencia.png',
                        mobile: '/img/_temp/bg-transparencia-mobile.png'
                    }
                }
            />
            <TertiaryBox
                title="Para tornar as construções coletivas possíveis, cultivamos a transparência como um valor"
            >
                <p>Para construir laços de confiabilidade com nossos públicos e parceiros, colocamos à disposição informações que permitam um conhecimento consistente a respeito da nossa forma de fazer gestão e tomar decisões.</p>
            </TertiaryBox>
            <div className="py-16">
                <Container className="px-5 sm:px-0">
                    <Row>
                        {/* <Col className="my-3 w-full sm:w-1/2 lg:w-4/12">
                            <CardGray
                                icon="icon-relatorio"
                                title="Relatórios"
                                description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet."
                                link="http://google.com.br"
                            />
                        </Col> */}
                        <Col className="my-3 w-full sm:w-1/2 lg:w-4/12">
                            <CardGray
                                icon="icon-paineis"
                                title="Paineis de resultado"
                                description="Os impactos positivos podem ser mensuráveis. Acreditamos que bons resultados merecem ser compartilhados."
                                description2=""
                                link={ROUTES.TRANSPARENCIA_PAINEIS()}
                            />
                        </Col>
                        {/* <Col className="my-3 w-full sm:w-1/2 lg:w-4/12">
                            <CardGray
                                icon="icon-usuario"
                                title="Usuários"
                                description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet."
                                link="http://google.com.br"
                            />
                        </Col> */}
                        {/* <Col className="my-3 w-full sm:w-1/2 lg:w-4/12">
                            <CardGray
                                icon="icon-livro"
                                title="Estudos"
                                description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet."
                                link="http://google.com.br"
                            />
                        </Col> */}
                        <Col className="my-3 w-full sm:w-1/2 lg:w-4/12">
                            <CardGray
                                icon="icon-parcerias"
                                title="Parcerias"
                                description="Temos muito orgulho das parcerias firmadas ao longo desses anos"
                                description2=""
                                link={ROUTES.TRANSPARENCIA_PARCEIROS()}
                            />
                        </Col>
                        <Col className="my-3 w-full sm:w-1/2 lg:w-4/12">
                            <CardGray
                                icon="icon-editais"
                                title="Editais e seleções"
                                description="Publicizamos as oportunidades que criamos para gerar transformações."
                                description2=""
                                // link={ROUTES.EDITAISESELECOES}
                            />
                        </Col>
                    </Row>
                </Container>
            </div>
            <FooterNavigate />
        </>
    )
}
