import { FC, useRef, useState } from 'react';
import './style.scss';

interface CategoriesAndSearchProps {
    className?: string;
    hideSearch?: boolean;
    items: Array<{
        label: string;
        value: string;
    }>
    onChangeCategory: (value: string) => void;
}

export const CategoriesAndSearch: FC<CategoriesAndSearchProps> = ({ className, onChangeCategory, hideSearch, items }) => {
    const [selected, setSelected] = useState('');
    const [searchOpen, setSearchOpen] = useState(false);
    const inputEl: any = useRef(null);

    function handleCategory(value: string) {
        setSelected(value);
        onChangeCategory(value);
    }
    return (
        <div className={`categories-and-search-component ${className || ''}`}>
            <ul className="flex flex-1 flex-col sm:flex-row justify-center space-y-2 sm:space-y-0 space-x-0 sm:space-x-5">
                <li>
                    <button 
                        className={`btn w-full sm:w-auto btn-primary ${selected === '' ? '' : 'outline'}`}
                        onClick={() => handleCategory('')}
                    >
                        Todos
                    </button>
                </li>
                {
                    items.map((item, index) => (
                        <li key={index}>
                            <button 
                                className={`btn w-full sm:w-auto btn-primary ${selected === item.value ? '' : 'outline'}`}
                                onClick={() => handleCategory(item.value)}
                            >
                                {item.label}
                            </button>
                        </li>
                    ))
                }
            </ul>
            {!hideSearch && 
              <div className={`categories-and-search-component__search ${searchOpen ? 'open' : ''}`}>
                  <form className="box">
                      <input 
                          ref={inputEl}
                          type="text" 
                          placeholder="Procurando algo?"
                      />
                      <button 
                          type="button" 
                          className="categories-and-search-component__search--btn-open"
                          onClick={() => {
                              setSearchOpen(true);
                              setTimeout(() => {
                                  inputEl.current.focus();
                              }, 300);
                          }}
                      >
                          <img src={require('../../assets/imgs/busca.png').default} alt=""/>
                      </button>
                  </form>
              </div>
            }
        </div>
    )
}
