import React from 'react';
import { ProvideLayout } from './contexts';
import Routes from './routes';

const App = () => {
    return (
        <ProvideLayout>
            <Routes />
        </ProvideLayout>
    )
}

export default App;