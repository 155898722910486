import React from 'react';
import { Link } from 'react-router-dom';
import { BannerHeader, TertiaryBox } from '../../components';
import { ROUTES } from '../../constants';
import Nav from './Nav';
import Posts from './Posts';
import Transparencia from './Transparencia';

export default function HomePage() {
    return (
        <>
            <BannerHeader
                imgs={
                    {
                        desktop: '/img/_temp/bg-home.png',
                        mobile: '/img/_temp/bg-home-mobile.png'
                    }
                }
            >
                <div className="space-y-6">
                    {/* <Link to="/"><img src={require('../../assets/imgs/logo.png').default} alt=""/></Link> */}
                    
                    <h2 className="text-primary sm:text-5xl"><strong>Inspirar e engajar, essa é a nossa <br />missão</strong></h2>
                </div>
            </BannerHeader>
            <Nav />
            {/* <TertiaryBox 
                className="sm:py-28"
                category="Observatório Social" 
                title="Boas decisões são guiadas por dados confiáveis e pesquisas consistentes"
            >
                <div className="mt-10">
                    <p>Neste período de grandes transformações, uma das tarefas que abraçamos é incluir observações relevantes para guiar empreendedores sociais e agentes da transformação.</p>
                    <Link to={ROUTES.OBSERVATORIO} className="btn btn-white outline mt-5">Ver mais</Link>
                </div>
            </TertiaryBox> */}
            <TertiaryBox 
                className="sm:py-28"
                category="Notícias" 
                title="Acompanhe aqui nossas últimas novidades"
            >
                <div className="mt-10">
                    <p></p>
                    <Link to={ROUTES.NOTICIAS} className="btn btn-white outline mt-5">Ver mais</Link>
                </div>
            </TertiaryBox>
            <div className="transform lg:translate-y-10 lg:-mt-10 space-y-5">
                <Posts />
            </div>
            <Transparencia />
        </>
    )
}