import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { BannerHeader, Container, FooterNavigate, Loading } from '../../components';
import { Services } from '../../services';
import './style.scss';
import Helmet from 'react-helmet';
import Moment from 'moment';


const initialTabs = {
  sobre: false,
  oQuePode: false,
  quemPode: false,
  periodo: false,
  etapas: false,
  regulamento: false,
  conheca: false,
}

export default function EditaisPostPage(props: any) {
  const params: any = useParams();
  const [data, setData] = useState<any>();
  const [type, setType] = useState<any>(0);
  const [loading, setLoading] = useState(true);
  const [invalidId, setInvalidId] = useState(false);

  const [openTabs, setopenTabs] = useState<any>(initialTabs);
  const [refresh, setRefresh] = useState(true);

  useEffect(() => {
    if (props?.location?.state) {
      setData(props?.location?.state?.data);
      setLoading(false);
    } else {
      loadData(params?.id);
    }
    console.log(type)
  }, []);

  async function loadData(id: any) {
    setLoading(true);

    try {
      const service = Services.Editais.getId;
      const resp = await service(id);
      if (resp?.data) {
        let active =
          (Moment(resp.data.dataInicioInscricao).format('YYYYMMDD') >= Moment(new Date()).format('YYYYMMDD'))
          && (Moment(resp.data.dataFimInscricao).format('YYYYMMDD') <= Moment(new Date()).format('YYYYMMDD'));
        setData({ ...resp?.data, activeEdital: active })
        return;

      }

      setInvalidId(true);
    } catch (error) {
      alert('Algo deu errado. Tente novamente.')
    } finally {
      setLoading(false);
    }
  }

  function getType() {
    let strType = '';
    strType = 'Editais e Seleções'
    return strType;

  }

  function openTab(type: string) {
    const temp = openTabs;
    temp[type] = !temp[type];
    setopenTabs(temp);
    setRefresh(!refresh);
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{data?.titulo}</title>
        <meta name="description" content={data?.titulo} />
        <meta property="og:title" content={data?.subTitulo} />
        <meta property="og:image" content={data?.urlDestaque} />
      </Helmet>
      {
        data?.dataFormatada && (
          <BannerHeader
            category={getType()}
            imgs={
              {
                desktop: '/img/_temp/bg-editais.png',
                // desktop: data?.urlDestaque,
                mobile: '/img/_temp/bg-editais-mobile.png',
              }
            }
            mini={true}
          />
        )
      }
      {
        !data?.dataFormatada && (
          <BannerHeader
            category={getType()}
            imgs={
              {
                desktop: '/img/_temp/bg-editais.png',
                // desktop: data?.urlDestaque,
                mobile: '/img/_temp/bg-editais-mobile.png',
              }
            }
            mini={true}
          />
        )
      }
      {
        loading
          ? <Loading />
          : invalidId
            ? (
              // quando não existir o id na requisição
              <h2 className="text-center py-10">
                Página inválida
              </h2>
            )
            : (
              <div>
                <section className="headereditaisPostPage">
                  <Container>
                    <div className="contentHeader">
                      <div>
                        <h2>{data?.titulo}</h2>
                        <p>{data.resumo}</p>
                        <div className="actions">
                          <div className="context-actions">
                            <span className={`${data.activeEdital ? "active" : ""}`}>Inscrições {data.activeEdital ? "abertas" : "encerradas"}</span>
                            {data.activeEdital && <a href="">Fazer inscrição</a>}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Container>
                  <img className="w-full my-5" src={data?.urlImagem} alt={data?.alt} />
                </section>
                <section className="pageEditaisPostPage">
                  <Container>
                    <div className="values">
                      <div className="item">
                        <span>Inscrições</span>
                        <h2>{Moment(data.dataInicioInscricao).format('DD/MM/YYYY')} até  {Moment(data.dataFimInscricao).format('DD/MM/YYYY')}</h2>
                      </div>
                      {/* <div className="item">
                        <span>Valor do projeto</span>
                        <h2>{data.valorProjeto.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})} </h2>
                      </div> */}
                    </div>
                    <div className="list">
                      <div className={`item ${openTabs.sobre && 'active'}`} >
                        <h2 onClick={() => openTab('sobre')}>Sobre</h2>
                        <div className="content">
                          <p>{data.sobre}</p>
                        </div>
                      </div>
                      <div className={`item ${openTabs.oQuePode && 'active'}`} >
                        <h2 onClick={() => openTab('oQuePode')}>O que pode ser solicitado?</h2>
                        <div className="content">
                          <p>{data.itensSolicitados}</p>
                        </div>
                      </div>
                      <div className={`item ${openTabs.quemPode && 'active'}`} >
                        <h2 onClick={() => openTab('quemPode')}>Quem pode se inscrever?</h2>
                        <div className="content">
                          <p>{data.participanteCriterio}</p>
                        </div>
                      </div>
                      <div className={`item ${openTabs.periodo && 'active'}`} >
                        <h2 onClick={() => openTab('periodo')}>Período de inscrição</h2>
                        <div className="content">
                          <p>{data.detalhesPeriodoInscricao}</p>
                        </div>
                      </div>
                      <div className={`item ${openTabs.etapas && 'active'}`} >
                        <h2 onClick={() => openTab('etapas')}>Etapas</h2>
                        <div className="content">
                          <p>{data.etapas}</p>
                        </div>
                      </div>
                      <div className={`item ${openTabs.regulamento && 'active'}`} >
                        <h2 onClick={() => openTab('regulamento')}>Regulamento</h2>
                        <div className="content">
                          {/* <iframe src={data.urlRegulamento}></iframe> */}
                          <a href={data.urlRegulamento} target="_blank" download>Download</a>
                        </div>
                      </div>
                      <div className={`item ${openTabs.conheca && 'active'}`} >
                        <h2 onClick={() => openTab('conheca')}>Conheça o programa</h2>
                        <div className="content">
                          <p>{data.metaDescricao}</p>
                        </div>
                      </div>
                    </div>
                  </Container>
                </section>
              </div>
            )

      }
      <FooterNavigate />
    </>
  )
}