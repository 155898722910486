import React, { FC } from 'react';
import { Link } from '..';
import './style.scss';

interface CardProps {
    className?: string;
    isFeatured?: boolean;
    title?: string;
    subTitle?: string;
    date?: string;
    category?: string;
    link: any;
    image?: string;
}

export const Card: FC<CardProps> = ({ isFeatured, title, date, category, link, image, className, subTitle }) => {
    return (
        <>
            <Link to={link} className={`card-component ${isFeatured ? 'featured' : ''} ${className || ''}`}>
                {image && <img src={image} className="card-component--img" />}

            </Link>
            <Link to={link}>
                <div className="card-component__content bg-white">
                    {
                        (date) && (
                            <span>
                                {date}
                            </span>
                        )
                    }
                    {title && <h4 className="mt-2 text-lg text-justify"><strong>{title}</strong></h4>}
                    {subTitle && <p className="mt-2 text-justify">{subTitle}</p>}
                </div>
            </Link>
        </>
    )
}
