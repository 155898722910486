export const data = [
    {
        date: 'Maio/1971',
        title: 'O começo',
        description: '<p>Foi aqui, no dia 29 de maio, que a Federação Nacional das Associações do Pessoal da Caixa Econômica Federal (Fenae) foi fundada, durante o 6º Congresso Nacional das Associações de Pessoal, em Curitiba (PR), para dar maior integração e unidade ao movimento associativo dos empregados da Caixa.</p>'
    },
    {
        date: 'Janeiro/2005',
        title: 'O movimento',
        description: '<p>Neste ano, foi criado o Comitê de Responsabilidade Social da Fenae e, a partir dele, nasceu o programa de desenvolvimento sustentável da Fenae, o Movimento Solidário.</p>'
    },
    {
        date: 'Janeiro/2007',
        title: 'A criação',
        description: '<p>Aqui o Instituto Fenae Transforma é então criado, inicialmente com o propósito de ser a estrutura responsável por abrigar o Programa Movimento Solidário e, posteriormente, tornando-se o executor de todas as iniciativas da Fenae voltadas para a transformação da sociedade.</p>'
    },
]