import React from 'react';
import { BannerHeader, Card, CategoriesAndSearch, Col, Container, FooterNavigate, Row, TertiaryBox } from '../../components';

export default function ObservatorioPage() {

    function handleCategory(value: string) {
        console.log(value);
    }

    return (
        <>
            <BannerHeader
                title={
                    <span>
                        Observatório<br />
                        social
                    </span>
                }
                imgs={
                    {
                        desktop: '/img/_temp/bg-sobre.png',
                        mobile: '/img/_temp/bg-sobre-mobile.png'
                    }
                }
            />
            <TertiaryBox
                title="Chamada Secundária mono dolor jacta est lorem mono ajacta est mono dolor"
            >
                <div>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer tortor magna, malesuada eget tincidunt quis, consequat vitae nisl. Proin ultrices laoreet iaculis. Aliquam pellentesque, libero ac scelerisque pretium, odio ipsum condimentum est, condimentum scelerisque enim massa quis mauris.</p>
                </div>
            </TertiaryBox>
            <Container>
                <div className="text-center py-16">
                    <h2><strong>Acompanhe aqui nossas últimas novidades</strong></h2>
                    <CategoriesAndSearch
                        items={[
                            {
                                label: 'Ensaios',
                                value: 'ensaios',
                            },
                            {
                                label: 'Estudos',
                                value: 'estudos',
                            }
                        ]}
                        onChangeCategory={handleCategory}
                    />
                </div>

                <Row className="mb-5 sm:mb-16">
                    <Col className="w-full sm:w-1/2 mb-5">
                        <Card
                            title="Título do vídeo lorem mono dolor ajacta est"
                            link="/"
                            isFeatured
                            image="/img/_temp/card-featured.png"
                            date="12 de setembro de 2020"
                            category="Ensaios"
                        />

                    </Col>
                    <Col className="w-full sm:w-1/2 mb-5">
                        <Card
                            title="Título do vídeo lorem mono dolor ajacta est"
                            link="/"
                            isFeatured
                            image="/img/_temp/card-featured.png"
                            date="12 de setembro de 2020"
                            category="Ensaios"
                        />
                    </Col>
                </Row>
                <div className="px-6 sm:px-0">
                    <Row className="mb-16">
                        <Col className="w-full sm:w-1/3 mb-5">
                            <Card
                                title="Título do vídeo lorem mono dolor ajacta est"
                                link="/"
                                image="/img/_temp/card-featured.png"
                                date="12 de setembro de 2020"
                                category="Ensaios"
                            />

                        </Col>
                        <Col className="w-full sm:w-1/3 mb-5">
                            <Card
                                title="Título do vídeo lorem mono dolor ajacta est"
                                link="/"
                                image="/img/_temp/card-featured.png"
                                date="12 de setembro de 2020"
                                category="Ensaios"
                            />
                        </Col>
                        <Col className="w-full sm:w-1/3 mb-5">
                            <Card
                                title="Título do vídeo lorem mono dolor ajacta est"
                                link="/"
                                image="/img/_temp/card-featured.png"
                                date="12 de setembro de 2020"
                                category="Ensaios"
                            />
                        </Col>
                        <Col className="w-full sm:w-1/3 mb-5">
                            <Card
                                title="Título do vídeo lorem mono dolor ajacta est"
                                link="/"
                                image="/img/_temp/card-featured.png"
                                date="12 de setembro de 2020"
                                category="Ensaios"
                            />

                        </Col>
                        <Col className="w-full sm:w-1/3 mb-5">
                            <Card
                                title="Título do vídeo lorem mono dolor ajacta est"
                                link="/"
                                image="/img/_temp/card-featured.png"
                                date="12 de setembro de 2020"
                                category="Ensaios"
                            />
                        </Col>
                        <Col className="w-full sm:w-1/3 mb-5">
                            <Card
                                title="Título do vídeo lorem mono dolor ajacta est"
                                link="/"
                                image="/img/_temp/card-featured.png"
                                date="12 de setembro de 2020"
                                category="Ensaios"
                            />
                        </Col>
                    </Row>
                </div>
            </Container>
            {/* TODO: toda página */}
            <FooterNavigate />
        </>
    )
}