import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation
} from "react-router-dom";
import Layout from "../pages/Layout";

export default function Routes() {
  const ScrollTop = () => {
    const [qtyScroll, setQtyScroll] = useState(0);
    const locationHook = useLocation();
    const { pathname } = locationHook;

    useEffect(() => {
      if (qtyScroll) {
        try {
          window.scrollTo(0, 0);
          setTimeout(() => {
            let i = 1;
            let int = setInterval(function () {
              window.scrollTo(0, i);
              i += 20;
              if (i >= 0) clearInterval(int);
            }, 5);
          }, 100)
        } catch (error) {
          window.scrollTo(0, 0);
        }
      }
      setQtyScroll(qtyScroll + 1);
    }, [pathname]);

    return <></>;
  };
  
  return (
    <Router>
      <ScrollTop />
      <Switch>
          <Route path="/" component={Layout} />
      </Switch>
    </Router>
  );
}