import React from 'react';
import { BannerHeader, Container, FooterNavigate, PostFull, TertiaryBox } from '../../components';
import { posts } from './data';
import Timeline from './Timeline';

export default function SobrePage() {
    return (
        <>
            <BannerHeader
                title={
                    <span>
                        Sobre o<br />
                        Fenae Transforma
                    </span>
                }
                imgs={
                    {
                        desktop: '/img/_temp/bg-sobre.png',
                        mobile: '/img/_temp/bg-sobre-mobile.png'
                    }
                }
            />
            <TertiaryBox
                category="Missão"
                title="A Missão do Instituto Fenae Transforma é atuar na gestão de projetos e investimentos para geração de impacto positivo na sociedade."
            >
                <div className="mt-8">
                    <p>Nós do Instituto Fenae acreditamos que a construção do bem estar socioambiental passa pela ação coletiva. É através do estreitamento de laços que as pessoas podem se engajar na transformação de suas realidades. </p>
                </div>
            </TertiaryBox>
            {
                posts?.map((item, index) => (
                    <PostFull {...item} isRight={index % 2 !== 0} key={index} />
                ))
            }
            <div className="bg-secondary py-10 sm:pt-16 text-white">
                <Container>
                    <div className="sm:text-center px-5 sm:px-0">
                        <h2 className="text-primary mb-4"><strong>Conheça um pouco da nossa história</strong></h2>
                        <p>Saiba um pouco mais sobre como foi o caminho do Instituto até hoje.</p>
                    </div>
                    <Timeline />
                </Container>
            </div>
            {/* TODO: timeline */}
            {/* TODO: caroussel */}
            <FooterNavigate />
        </>
    )
}