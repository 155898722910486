import React, {
  useContext,
  createContext,
  FunctionComponent,
  useState
} from 'react';
import { LayoutProps } from './use-layout.props';

const layoutContext = createContext({} as LayoutProps);

export const useLayout = (): LayoutProps => {
  return useContext(layoutContext);
};

function useProvideLayout(): LayoutProps {
  const [fullLoading, setFullLoading] = useState<boolean>(false); // adiciona um loading na página toda quando é o primeiro acesso e precisa carregar os dados.
  const [nav, setNav] = useState(false);


  return {
    fullLoading,
    nav,
    setFullLoading,
    setNav
  };
}

export const ProvideLayout: FunctionComponent = ({ children }) => {
  const layout: LayoutProps = useProvideLayout();
  return <layoutContext.Provider value={layout}>{children}</layoutContext.Provider>;
};