import React from "react";
import { BannerHeader, Container, FooterNavigate, TertiaryBox } from "../../components/";
import "./style.scss";
import download from '../../assets/imgs/download-relatorio.png'

export default function ContatoPage() {

    return (
        <>
            <BannerHeader
                title="Relatórios"
                imgs={
                    {
                        desktop: '/img/_temp/bg-relatorios.png',
                        mobile: '/img/_temp/bg-relatorios-mobile.png'
                    }
                }
            />
            <div className="sm:px-60 py-10 bg-cinza">
                <h2 className="text-center">
                    <strong>Relatórios sobre o assunto um</strong>
                </h2>
                <div className="flex sm:items-center items-start my-5 justify-between relatorio flex-col sm:flex-row">
                    <div>
                        <p>Lorem ipsum dolor sit amet.</p>
                    </div>
                    <div>
                        <a className="download-button">Download <img className="ml-2" src={download} alt="" /></a>
                    </div>
                </div>
                <div className="flex sm:items-center items-start my-5 justify-between relatorio flex-col sm:flex-row">
                    <div>
                        <p>Nulla purus nisl, luctus quis suscipit mattis, ultrices sit amet magna.</p>
                    </div>
                    <div>
                        <a className="download-button">Download <img className="ml-2" src={download} alt="" /></a>
                    </div>
                </div>
                <div className="flex sm:items-center items-start my-5 justify-between relatorio flex-col sm:flex-row">
                    <div>
                        <p>Aliquam eu volutpat nibh, non vehicula dolor.</p>
                    </div>
                    <div>
                        <a className="download-button">Download <img className="ml-2" src={download} alt="" /></a>
                    </div>
                </div>
                <div className="flex sm:items-center items-start my-5 justify-between relatorio flex-col sm:flex-row">
                    <div>
                        <p>Luctus quis suscipit mattis, ultrices sit amet magna.</p>
                    </div>
                    <div>
                        <a className="download-button">Download <img className="ml-2" src={download} alt="" /></a>
                    </div>
                </div>
                <h2 className="text-center">
                    <strong>Relatórios sobre o assunto dois</strong>
                </h2>
                <div className="flex sm:items-center items-start my-5 justify-between relatorio flex-col sm:flex-row">
                    <div>
                        <p>Lorem ipsum dolor sit amet.</p>
                    </div>
                    <div>
                        <a className="download-button">Download <img className="ml-2" src={download} alt="" /></a>
                    </div>
                </div>
                <div className="flex sm:items-center items-start my-5 justify-between relatorio flex-col sm:flex-row">
                    <div>
                        <p>Nulla purus nisl, luctus quis suscipit mattis, ultrices sit amet magna.</p>
                    </div>
                    <div>
                        <a className="download-button">Download <img className="ml-2" src={download} alt="" /></a>
                    </div>
                </div>
            </div>
            <FooterNavigate />
        </>
    )
}