import React, { FC } from 'react';
import './style.scss';

interface SquareProps {
    className?: string;
    position?: 'tl' | 'tr' | 'bl' | 'br';
}

export const Square: FC<SquareProps> = ({ position, className, children }) => {
    const p = position || 'br';
    return <div className={`square-component absolute ${p} ${className || ''}`}>{children}</div>
}
